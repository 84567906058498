import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// assets
import Sponsor1 from '../assets/sponsors/Sealiions sponsor.JPEG'
import Sponsor2 from '../assets/sponsors/chapelhouse-slider.png'
import Sponsor3 from '../assets/sponsors/spa-seven.JPG'
import Sponsor4 from '../assets/sponsors/Smile Chic Sponsor.JPEG'
import Sponsor5 from '../assets/sponsors/offyourbox.JPG'
import Sponsor6 from '../assets/sponsors/minnieandteds.JPG'
// import Sponsor7 from '../assets/sponsors/maskell2.JPG'
import Sponsor8 from '../assets/sponsors/curves.JPG'
// import Sponsor9 from '../assets/sponsors/arigibianchi.JPG'
// import Sponsor10 from '../assets/sponsors/kissdental.JPG'
// import Sponsor11 from '../assets/sponsors/ab.JPG'
// import Sponsor12 from '../assets/sponsors/parea.JPG'
import Sponsor13 from '../assets/sponsors/melanomauk.JPG'
import Sponsor14 from '../assets/sponsors/franklyn.JPG'
import Sponsor15 from '../assets/sponsors/lshauto.PNG'
// import Sponsor16 from '../assets/sponsors/pepperberry.JPG'
// import Sponsor17 from '../assets/sponsors/happymotorfinance.jpeg'
import Sponsor18 from '../assets/sponsors/Kenny Waste Sponsor.PNG'
// import Sponsor19 from '../assets/sponsors/cheshire-retreat.jpeg'
import Sponsor20 from '../assets/sponsors/New York New York Sponsor Logo.JPEG'
import Sponsor21 from '../assets/sponsors/Margaret Dabbs Sponsor logo.jpeg'

export default function Sponsors() {
  return (
    <div className="">
      <Carousel 
        centerMode centerSlidePercentage={25} 
        autoPlay labels infiniteLoop 
        showThumbs={false} useKeyboardArrows
        interval={1500} showIndicators={false}
      >
        <a href="https://sealions.com">
            <div>
                <img src={Sponsor1} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
        <a href="https://www.chapelhouse.co.uk/">
            <div>
                <img src={Sponsor2} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
         </a>
        <a href="https://www.spaseven.co.uk/">
            <div>
                <img src={Sponsor3} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
        <a href="https://www.smilechic.co.uk">
            <div>
                <img src={Sponsor4} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
        <a href="https://www.offyourbox.uk/">
            <div>
                <img src={Sponsor5} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
        <a href="https://minnieandteds.co.uk/">
            <div>
                <img src={Sponsor6} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
        {/* <a href="https://www.maskelloptometrists.com/">
            <div>
                <img src={Sponsor7} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a> */}
        <a href="https://wearecurves.com/">
            <div>
                <img src={Sponsor8} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
        {/* <a href="https://www.arighibianchi.co.uk/">
            <div>
                <img src={Sponsor9} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a> */}
        {/* <a href="https://www.kissdental.co.uk/">
            <div>
                <img src={Sponsor10} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a> */}
        {/* <a href="https://www.happyradiouk.com/">
            <div>
                <img src={Sponsor11} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a> */}
        {/* <a href="https://www.pareaalderley.com/">
            <div>
                <img src={Sponsor12} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a> */}
        <a href="https://www.melanomauk.org.uk/">
            <div>
                <img src={Sponsor13} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
        <a href="https://www.franklynfm.co.uk/">
            <div>
                <img src={Sponsor14} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
        <a href="https://www.lshauto.co.uk/">
            <div>
                <img src={Sponsor15} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
        {/* <a href="http://www.pepperberrydaynurseries.com/">
            <div>
                <img src={Sponsor16} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a> */}
        {/* <a href="https://happymotorfinance.co.uk/">
            <div>
                <img src={Sponsor17} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a> */}
        <a href="https://www.kennywastemanagement.co.uk/">
            <div>
                <img src={Sponsor18} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
        {/* <a href="https://cheshire-retreat.co.uk/">
            <div>
                <img src={Sponsor19} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a> */}
        <a href="https://www.newyorknewyorkbar.co.uk/">
            <div>
                <img src={Sponsor20} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
        <a href="https://www.margaretdabbs.com/">
            <div>
                <img src={Sponsor21} alt="Happy Radio UK Sponsors" width={100} height={100} />
            </div>
        </a>
      </Carousel>
    </div>
  )
}
